<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <div v-if="isLoading" id="loadingBackground">
      <v-progress-circular
        indeterminate
        id="progress"
        :size="100"
      ></v-progress-circular>
    </div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="text-center">
          <h1>
            Обращение по работе транспорта
          </h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="4"
          sm="6"
          offset-sm="3"
          xs="12"
        >
          <v-select
            v-model="category"
            :items="category_items"
            :rules="[(v) => !!v || 'Выбор категории обязателен']"
            item-value="1"
            label="Категория"
            color="grey darken-4"
            value="ANAL"
            required
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="4"
          sm="6"
          offset-sm="3"
          xs="12"
        >
          <v-autocomplete
            v-model="muns"
            :items="muns_items"
            item-text="name"
            :rules="[(v) => !!v || 'Выбор муниципального образования обязателен']"
            label="Муниципальное образование"
            color="grey darken-4"
            required
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="4"
          sm="6"
          offset-sm="3"
          xs="12"
        >
          <v-autocomplete
            v-model="routeNumber"
            :items="route_items"
            item-text="keks"
            item-value="1"
            label="Номер маршрута"
            color="grey darken-4"
            required
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="4"
          sm="6"
          offset-sm="3"
          xs="12"
        >
          <v-text-field
            v-model="busStop"
            label="Остановочный пункт"
            color="grey darken-4"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="4"
          sm="6"
          offset-sm="3"
          xs="12"
        >
          <v-text-field
            v-model="problem"
            label="Краткое описание проблемы"
            color="grey darken-4"
            counter="counterEn 300 counter : true"
            :rules="[() => !!problem && problem.length <= 301 || 'Не должно быть пустым и превышать 300 символов']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="4"
          sm="6"
          offset-sm="3"
          xs="12"
          class="whiteSpacePre"
        >
          <v-text-field
            @click="phoneClick"
            v-mask="'+7 (###) ###-##-##'"
            v-model="phoneNumber"
            :rules="phoneRules"
            label="+7 (- - -)  - - -  - -  - -"
            color="grey darken-4"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          hidden
          class="text-center">
          <h5>
            Указывая номер телефона, вы даете согласие на отправку ответов в СМС
          </h5>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="4"
          sm="6"
          offset-sm="3"
          xs="12"
        >
          <v-file-input
            v-model="file"
            ref="file"
            :show-size="8000"
            @change="check_file"
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-camera"
            label="Загрузить фотографию"
            truncate-length="15"
          ></v-file-input>
          <v-text-field class="d-none"
            v-model="file_name"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="4"
          sm="6"
          offset-sm="3"
          xs="12"
        >
          <v-btn
            id="submit"
            width="100%"
            @click="validate"
          >
            Отправить обращение
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <success-modal v-if="isSuccessMessage" :dialog="isSuccessMessage" @close="closeSuccessMessage"/>
  </v-form>
</template>

<script>
import axios from 'axios';
import { uuid } from 'vue-uuid';
import SuccessModal from '@/components/SuccessModal';
import * as categoryList from '@/json/category.json';
import * as munsList from '@/json/muns.json';
import * as routeList from '@/json/route_list.json';

const getLocation = () => new Promise((resolve, reject) => {
  if (!('geolocation' in navigator)) {
    reject(new Error('Geolocation is not available.'));
  }

  navigator.geolocation.getCurrentPosition((pos) => {
    resolve(pos);
  }, () => {
    resolve({});
  }, {
    timeout: 5000,
  });
});

export default {
  name: 'FeedbackForm',
  components: {
    SuccessModal,
  },
  data: () => ({
    file_name: '',
    file: null,
    valid: false,
    routeNumber: null,
    route_items: routeList.default,
    busStop: '',
    problem: '',
    phoneNumber: '',
    phoneRules: [(v) => {
      if (v.length === 0) {
        return false;
      }
      return (/^((\+7 ))(\(?\d{3}\)[- ]?)?[\d\- ]{10}$/.test(v)) || 'Введите корректный номер';
    }],
    category: null,
    category_items: categoryList.default,
    muns: null,
    muns_items: munsList.default,
    isSuccessMessage: false,
    isLoading: false,
  }),
  methods: {
    phoneClick() {
      if (this.phoneNumber === '' || this.phoneNumber !== '+7 (') {
        this.phoneNumber = '+7 (';
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.getAppealData();
      }
    },
    check_file() {
      if (this.file === null) {
        this.file_name = '';
      } else if (this.file.type === 'image/png' || this.file.type === 'image/jpeg' || this.file.type === 'image/bmp') {
        if (this.file.size > 10000000) {
          this.$refs.file.reset();
          this.$refs.file.blur();
        }
      } else {
        alert('Можно загрузить только фотографию!');
        this.$refs.file.reset();
        this.$refs.file.blur();
      }
    },
    async getAppealData() {
      this.isLoading = true;
      const location = await getLocation();

      // O4KO
      if (this.file === null) {
        this.file_name = '';
      } else {
        const formData = new FormData();
        const fileName = ''.concat(uuid.v4(), '.', this.file.type.split('/')[1]);
        formData.append('mimeType', this.file.type);
        formData.append('file', this.file, fileName);

        await axios.post('https://mtdimo.ru/photo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7',
          },
        });
        this.file_name = ''.concat('https://mtdimo.ru/file/', fileName);
      }
      // O4KO END

      const appealData = {
        category: this.category,
        muns: this.muns,
        routeNumber: this.routeNumber,
        busStop: this.busStop,
        problem: this.problem,
        phoneNumber: this.phoneNumber.slice(1),
        geoLatitude: location?.coords?.latitude,
        geoLongitude: location?.coords?.longitude,
        file: this.file_name,
      };

      await axios
        .post('https://mtdimo.ru/feedback', appealData);

      this.isLoading = false;
      this.isSuccessMessage = true;
      this.category = null;
      this.muns = null;
      this.routeNumber = null;
      this.busStop = '';
      this.problem = '';
      this.phoneNumber = '';
      this.file = null;
      this.file_name = '';
    },
    closeSuccessMessage() {
      this.isSuccessMessage = false;
    },
  },
};
</script>

<style lang="scss">
$yellow: #fdce1c;

#submit {
  background-color: $yellow;
}

#progress {
  color: $yellow;
}

#loadingBackground {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.whiteSpacePre label{
  white-space: pre!important;
}
</style>
