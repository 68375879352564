<template>
  <v-row>
    <v-col
      md="6"
      offset-md="3"
      xs="14"
      class="d-flex justify-center"
    >
      <header
        class="d-flex justify-space-around mb-10">
        <img
          class="mr-8"
          alt="Транспорт подмосковья"
          src="../assets/transport.png"
          />
        <img
          alt="Министерство транспорта и дорожной инфраструктуры Москоской области"
          src="../assets/herb.png"
        />
      </header>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped>
img {
  max-height: 140px;
  max-width: 880px;
}

@media (max-width: 600px) {
  img {
    max-height: 100px;
    max-width: 155px;
  }
}
</style>
